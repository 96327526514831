<template>
  <a :href="href" target="_blank" class="flex items-start space-x-4 text-primary mb-3">
    <span class="w-6 flex-shrink-0 flex justify-center">
      <slot name="icon">
        <BIcon :icon="icon" />
      </slot>
    </span>

    <span>
      <span class="text-primary">
        <slot name="label">
          {{ label }}
        </slot>
      </span>
    </span>
  </a>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'globe'
    },
    label: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: null
    }
  }
}
</script>