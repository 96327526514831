<template>
  <div>
    <section :style="{ backgroundImage: hero }" class="-mt-20 pt-40 pb-24 bg-cover bg-center relative">
      <div class="absolute inset-0 h-full w-full bg-header/[var(--bookapp-opacity-hero)]" />
      <b-container>
        <div class="w-full flex flex-col items-center justify-center">
          <div class="mb-16">
            <img
              v-if="$site.images.logo"
              :src="$site.images.logo.medium"
              class="max-h-[140px] sm:max-h-[170px]"
              alt="Logo"
            >
            <div
              v-else
              class="text-4xl text-secondary font-semibold"
            >
              {{ businessName }}
            </div>
          </div>

          <b-button v-if="$site.tabs.book" to="/book-now" variant="action">
            {{ $site.tabs.book.name }}
          </b-button>
        </div>
      </b-container>
    </section>

    <section v-if="$site.tabs.isEmpty" class="py-20">
      <b-container>
        <h2 class="text-primary text-xl font-bold text-center">
          {{ $t('pages.index.notSetUpTitle') }}
        </h2>
        <div class="text-center">
          {{ $t('pages.index.notSetUpContent', { location: businessName }) }}
        </div>
      </b-container>
    </section>

    <section v-if="$site.settings.notice.enabled" class="py-12">
      <b-container>
        <b-alert :icon="false" :truncate="isApp">
          <span v-html="formatHtml($site.settings.notice.content)" />
        </b-alert>
      </b-container>
    </section>

    <section v-if="$site.tabs.contact" class="py-10">
      <b-container>
        <h2 class="text-primary text-2xl font-bold text-center mb-8">
          {{ $t('pages.index.whereToFindUs') }}
        </h2>
        <div class="sm:flex items-start sm:space-x-6  rtl:space-x-reverse">
          <div v-if="$site.tabs.contact.address.showMap" class="w-full sm:w-1/2 mb-4 sm:mb-0">
            <component
              :is="$site.tabs.contact.address.showMap && $site.tabs.contact.address.displayAddress ? 'a' : 'div'"
              class="block"
              target="_blank"
              :href="$format.maps($site.tabs.contact.address.displayAddress)"
            >
              <img :src="mapUrl" class="w-full rounded overflow-hidden" />
            </component>
          </div>
          <div
            class="w-full max-w-sm"
            :class="$site.tabs.contact.address.showMap ? 'sm:w-1/2' : 'text-center mx-auto'"
          >
            <h4 class="text-secondary text-lg font-bold mb-2">
              {{ $site.tabs.contact.contact.business }}
            </h4>
            
            <template v-if="$site.tabs.contact.hours.enabled && $site.tabs.contact.businessHours.length > 0">
              <BusinessHours class="mb-2" />
            </template>

            <ContactLink
              v-if="$site.tabs.contact.contact.email"
              :href="'mailto:' + $site.tabs.contact.contact.email"
              :label="$site.tabs.contact.contact.email"
              icon="email"
            />
            
            <ContactLink
              v-if="$site.tabs.contact.contact.phone"
              :href="'tel:' + stripPhoneNumber($site.tabs.contact.contact.phone)"
              :label="$site.tabs.contact.contact.phone"
              icon="phone"
            />
            
            <ContactLink
              v-if="$site.tabs.contact.contact.website"
              :href="$format.link($site.tabs.contact.contact.website)"
              :label="$site.tabs.contact.contact.website"
              icon="globe"
            />
            
            <ContactLink
              v-if="$site.tabs.contact.address.displayAddress"
              :href="$format.maps($site.tabs.contact.address.displayAddress)"
              :label="$site.tabs.contact.address.displayAddress"
              icon="mapPin"
            />

            <template v-if="$site.tabs.contact.hours.enabled && !$site.tabs.contact.businessHours.length">
              <BusinessHours class="mb-2" />
            </template>

            <div class="mt-6 ml-10">
              <social-links :social-links="$site.settings.socialLinks" :class="$site.tabs.contact.address.showMap ? '' : 'justify-center'" />
            </div>

            
          </div>
        </div>
      </b-container>
    </section>

    <section v-if="$site.tabs.timetable" class="py-16">
      <b-container>
        <h2 class="text-primary text-2xl font-bold text-center mb-8">
          {{ $site.tabs.timetable.name }}: {{ $t('pages.index.today') }}
        </h2>
        <div>
          <div v-if="timetable.loading" class="text-center">
            <b-spinner label="Loading" />
          </div>

          <b-error v-if="timetable.error" :name="$t('pages.index.timetable')" />

          <template v-if="timetable.data">
            <div v-if="!timetable.data.length" class="m-3 text-center text-sm text-secondary">
              {{ $t('pages.index.noData') }}
            </div>
            <ul v-if="(timetable.data && timetable.data.length)" class="home-timetable-list">
              <li v-for="slot in timetable.data" :key="slot.data">
                {{ slot.startTimeLabel }}:
                <a v-if="slot.available" class="text-primary" @click="timetableSlotClicked(slot)">
                  {{ $t('general.serviceWithEmployee',{service: slot.services[0].service.title, employee: slot.services[0].employee.firstName}) }}
                </a>
                <span v-else>
                  {{ $t('general.serviceWithEmployee',{service: slot.services[0].service.title, employee: slot.services[0].employee.firstName}) }}
                </span>

                <small v-if="$site.settings.bookShowServicePrices && slot.totalPrice > 0">
                  - {{ slot.totalPriceLabel }}
                </small>

                <b-chip v-if="slot.classStatus === 'almost-full'" variant="warning">
                  {{ $t('general.almostFull') }}
                </b-chip>
                <b-chip v-if="slot.classStatus === 'full'" variant="secondary">
                  {{ $t('pages.index.full') }}
                </b-chip>
              </li>
            </ul>

            <div class="flex items-center justify-center">
              <b-button class="mt-4 mx-auto block" :to="{ path: '/timetable' }" variant="action">
                {{ $t('general.seeMore') }} »
              </b-button>
            </div>
          </template>
        </div>
      </b-container>
    </section>

    <section v-if="$site.tabs.review" class="py-16">
      <b-container>
        <h2 class="text-primary text-2xl font-bold text-center mb-8">
          {{ $site.tabs.review.name }}
        </h2>
        <div>
          <div v-if="$site.tabs.review.latest && $site.tabs.review.latest.length" class="grid sm:grid-cols-4 gap-4">
            <review
              v-for="(review, index) in $site.tabs.review.latest.slice(0, 3)"
              :key="index"
              :review="review"
            />
          </div>

          <b-button class="mt-4 mx-auto block" :to="{ path: '/reviews' }" variant="action">
            {{ $t('pages.index.showAll') }} »
          </b-button>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import Review from '../components/review/elements/Review.vue'
import SocialLinks from '@/components/layouts/elements/SocialLinks.vue'
import BusinessHours from '@/components/layouts/elements/BusinessHours.vue'
import ContactLink from '@/components/layouts/elements/ContactLink.vue'
import LayoutMixin from '@/mixins/layout.js'

import Utils from '@/utils'

import {
  BookingStage,
  createBookingRoute
} from '@/components/booking/elements/Detail.vue'

export default {
  components: {
    Review,
    SocialLinks,
    BusinessHours,
    ContactLink
  },
  mixins: [LayoutMixin],
  middleware: ['location'],
  data () {
    return {
      title: 'Home',
      reviews: {
        loading: false,
        data: null
      },
      timetable: {
        loading: false,
        error: false,
        data: null
      }
    }
  },
  fetch ({ store, params, route }) {
    return Promise.all([store.dispatch('location/getServices')])
  },
  computed: {
    hero () {
      return this.$site.images.heroImageCssUrl('large')
    },
    mapUrl () {
      return this.$site.location.mapUrl({ size: '450x300', zoom: 15 })
    },
    businessName () {
      if (this.$site.tabs.contact && this.$site.tabs.contact.business) {
        return this.$site.tabs.contact.business
      } else {
        return this.$site.location.title
      }
    },
    layout () {
      return this.$store.getters.layout
    },
    isApp () {
      return this.layout === 'app'
    }
  },
  mounted () {
    this.loadTimetable()
  },
  methods: {
    formatHtml (text) {
      return Utils.text.formatHtml(text)
    },
    stripPhoneNumber (text) {
      return Utils.text.stripPhoneNumber(text)
    },
    async loadTimetable () {
      this.timetable.loading = true

      try {
        const response = await this.$api.booking.timetableDay(new Date())
        this.timetable.data = response.slots
      } catch (error) {
        // this.$sentry.captureException(error)
        this.timetable.loading = false
        this.timetable.error = true
      }

      this.timetable.loading = false
    },
    tintColor (tint) {
      return this.$site.colors.content['background-' + tint.toString()]
    },
    timetableSlotClicked (slot) {
      this.$store.dispatch('booking/clearServiceSelections')

      for (let i = 0; i < slot.services.length; i++) {
        const slotService = slot.services[i]

        this.$store.dispatch('booking/setServiceSelected', {
          service: slotService.service,
          selected: true,
          toBack: true
        })
      }

      this.$store.dispatch('booking/setSelectedSlot', {
        slot,
        mode: 'timetable'
      })
      const bookingUrlQuery = this.$store.getters['booking/urlQuery']

      if (this.$site.settings.showGroupDetail) {
        this.$router.push(
          createBookingRoute(bookingUrlQuery, BookingStage.DETAIL)
        )
      } else {
        this.$router.push(
          createBookingRoute(bookingUrlQuery, BookingStage.CUSTOMER)
        )
      }
    }
  }
}
</script>

<style>
.home-timetable-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.home-timetable-list li {
  margin-bottom: 10px;
  text-align: center !important;
}

.home-timetable-list li a {
  text-align: left !important;
  font-size: 14px;
}

.map {
  width: 100%;
  height: 100%;
  min-height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.hero-section {
  background-position: center;
  background-size: cover;
  position: relative;
  background-image: var(--bookapp-hero-background-image-small);
  color: white;
}

.hero-section > .container {
  display: block;
  position: relative;
}

.hero-section .scroll-indicator svg {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .hero-section {
    background-image: var(--bookapp-hero-background-image-medium);
  }
}

@media (min-width: 1200px) {
  .hero-section {
    background-image: var(--bookapp-hero-background-image-large);
  }
}

.hero-section:before {
  position: absolute;
  left: 0px;
  content: ' ';
  width: 100%;
  height: 100%;
  display: block;
  background: var(--bookapp-hero-background-color);
  opacity: var(--bookapp-hero-background-opacity);
}

.hero-logo {
  max-width: 100%;
  max-height: 200px;
  font-size: 3rem;
}

.scroll-indicator {
  -webkit-animation-name: bounce2;
  animation-name: bounce2;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes bounce2 {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(5px);
  }
}
@keyframes bounce2 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}
</style>
